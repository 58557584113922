import { actorsModule } from '@modules/actors/actors.module';
import { agendasModule } from '@modules/agendas/agendas.module';
import { authModule } from '@modules/auth/auth.module';
import { boardsModule } from '@modules/boards/boards.module';
import { commitmentsModule } from '@modules/commitments/commitments.module';
import { organizationsModule } from '@modules/organizations/organizations.module';
import { timeframesModule } from '@modules/timeframes/timeframes.module';
import { usersModule } from '@modules/users/users.module';
import { billingModule } from '@modules/billing/billing.module';

export const modules = {
	...actorsModule,
	...agendasModule,
	...authModule,
	...boardsModule,
	...commitmentsModule,
	...organizationsModule,
	...timeframesModule,
	...usersModule,
	...billingModule,
};
