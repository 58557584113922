import { App } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faHouse,
	faUser,
	faTableColumns,
	faThumbTack,
	faAnglesLeft,
	faAngleLeft,
	faAnglesRight,
	faAngleRight,
	faCalendar,
	faPlus,
	faGear,
	faRightFromBracket,
	faLifeRing,
	faCircle,
	faCircleXmark,
	faCircleCheck,
	faCheck,
	faCircleExclamation,
	faLink,
	faLightbulb,
	faSquareArrowUpRight,
	faArrowUpRightAndArrowDownLeftFromCenter,
	faBars,
	faXmark,
	faSpinnerThird,
	faAngleDown,
	faBold,
	faItalic,
	faUnderline,
	faStrikethrough,
	faParagraph,
	faHeading,
	faMoon,
	faSunBright,
	faCloudArrowUp,
	faPen,
	faTrashCan,
	faEllipsisVertical,
	faAngleUp,
	faGripDotsVertical,
	faAddressCard,
	faArrowsToDottedLine,
	faUsers,
	faUserGear,
	faHouseLock,
	faCircleQuestion,
	faUserPen,
	faUp,
	faFileLines,
	faCreditCard,
	faBuildings,
	faCircleEllipsis,
	faLock,
} from '@fortawesome/pro-regular-svg-icons';
import { faJira, faSlack, faGithub, faDiscord } from '@fortawesome/free-brands-svg-icons';

export type AvailableIcons = keyof typeof FONTAWESOME_ICONS;

export const FONTAWESOME_ICONS = {
	'fa-brands fa-discord': faDiscord,
	'fa-brands fa-github': faGithub,
	'fa-brands fa-jira': faJira,
	'fa-brands fa-slack': faSlack,
	'fa-regular fa-address-card': faAddressCard,
	'fa-regular fa-angle-down': faAngleDown,
	'fa-regular fa-angle-left': faAngleLeft,
	'fa-regular fa-angle-right': faAngleRight,
	'fa-regular fa-angle-up': faAngleUp,
	'fa-regular fa-angles-left': faAnglesLeft,
	'fa-regular fa-angles-right': faAnglesRight,
	'fa-regular fa-arrow-up-right-and-arrow-down-left-from-center':
		faArrowUpRightAndArrowDownLeftFromCenter,
	'fa-regular fa-arrows-to-dotted-line': faArrowsToDottedLine,
	'fa-regular fa-bars': faBars,
	'fa-regular fa-bold': faBold,
	'fa-regular fa-buildings': faBuildings,
	'fa-regular fa-calendar': faCalendar,
	'fa-regular fa-check': faCheck,
	'fa-regular fa-circle-check': faCircleCheck,
	'fa-regular fa-circle-ellipsis': faCircleEllipsis,
	'fa-regular fa-circle-exclamation': faCircleExclamation,
	'fa-regular fa-circle-question': faCircleQuestion,
	'fa-regular fa-circle-xmark': faCircleXmark,
	'fa-regular fa-circle': faCircle,
	'fa-regular fa-cloud-arrow-up': faCloudArrowUp,
	'fa-regular fa-credit-card': faCreditCard,
	'fa-regular fa-ellipsis-vertical': faEllipsisVertical,
	'fa-regular fa-file-lines': faFileLines,
	'fa-regular fa-gear': faGear,
	'fa-regular fa-grip-dots-vertical': faGripDotsVertical,
	'fa-regular fa-heading': faHeading,
	'fa-regular fa-house-lock': faHouseLock,
	'fa-regular fa-house': faHouse,
	'fa-regular fa-italic': faItalic,
	'fa-regular fa-life-ring': faLifeRing,
	'fa-regular fa-lightbulb': faLightbulb,
	'fa-regular fa-link': faLink,
	'fa-regular fa-lock': faLock,
	'fa-regular fa-moon': faMoon,
	'fa-regular fa-paragraph': faParagraph,
	'fa-regular fa-pen': faPen,
	'fa-regular fa-plus': faPlus,
	'fa-regular fa-right-from-bracket': faRightFromBracket,
	'fa-regular fa-spinner-third': faSpinnerThird,
	'fa-regular fa-square-arrow-up-right': faSquareArrowUpRight,
	'fa-regular fa-strikethrough': faStrikethrough,
	'fa-regular fa-sun-bright': faSunBright,
	'fa-regular fa-table-columns': faTableColumns,
	'fa-regular fa-thumbtack': faThumbTack,
	'fa-regular fa-trash-can': faTrashCan,
	'fa-regular fa-underline': faUnderline,
	'fa-regular fa-up': faUp,
	'fa-regular fa-user-gear': faUserGear,
	'fa-regular fa-user-pen': faUserPen,
	'fa-regular fa-user': faUser,
	'fa-regular fa-users': faUsers,
	'fa-regular fa-xmark': faXmark,
} as const;

export function useFontawesome(app: App) {
	library.add(...Object.values(FONTAWESOME_ICONS));

	app.component('BaseFontAwesomeIcon', FontAwesomeIcon);
}
